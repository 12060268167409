import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubscribeSection from "../components/Subscribe"

import { Form, Field } from "react-final-form"
import { FORM_ERROR } from "final-form"

import {
  FadeInTop,
  FadeInRight,
  FadeInTopList,
  gsap,
  useScrollImageReveal,
} from "../components/animations"
import {
  GatsbyImage,
  getImage,
  StaticImage,
  withArtDirection,
} from "gatsby-plugin-image"

import loadingSpinnerLight from "../assets/images/loading-spinner-light.svg"
import confettiAnimation from "../assets/images/confetti-animation.gif"

const ContactPage = () => {
  useScrollImageReveal()
  const pageData = useStaticQuery(graphql`
    query ContactPageData {
      smallIntroImage: file(name: { eq: "contact-hello-bg-small" }) {
        childImageSharp {
          gatsbyImageData
        }
      }

      largeIntroImage: file(name: { eq: "contact-hello-bg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }

      SEOData: sanityPageConnect {
        SEO {
          title: seoTitle
          description: seoDescription
        }
      }
    }
  `)

  const SEOData = pageData.SEOData.SEO

  return (
    <Layout>
      <Seo title={SEOData.title} description={SEOData.description} />
      <IntroSection
        data={{
          smallIntroImage: pageData.smallIntroImage,
          largeIntroImage: pageData.largeIntroImage,
        }}
      />
      <ContactForm />
      <SubscribeSection />
    </Layout>
  )
}

const IntroSection = ({ data }) => {
  const image = withArtDirection(getImage(data.largeIntroImage), [
    {
      media: "(max-width: 768px)",
      image: getImage(data.smallIntroImage),
    },
  ])

  return (
    <section className="pt-32 pb-24">
      <div className="mb-12 c-container-full md:flex">
        <FadeInTop delay={0.5}>
          <h1 className="font-headings text-3xl lg:text-5xl leading-tight lg:leading-tight mb-11">
            Do you need us?
          </h1>
        </FadeInTop>
        <ul className="w-max ml-auto space-y text-lg lg:text-xl font-headings2 text-[#666] pr-14 lg:pr-36 ">
          <FadeInTopList delay={1}>
            {["Say Hello", "E nle o!", "Nnoo!", "Sannu!", "Salut!"].map(
              greet => (
                <li key={greet}>{greet}</li>
              )
            )}
          </FadeInTopList>
        </ul>
      </div>
      <div className="js-img-reveal lg:w-11/12 mx-auto">
        <GatsbyImage
          image={image}
          className=" h-[340px] w-full"
          alt="hello phone"
        />
      </div>
    </section>
  )
}

const SubmitSuccess = ({ setIsSubmitted }) => {
  const successWrapper = useRef(null)

  // scroll to form submission success
  useEffect(() => {
    let distanceFromTop =
      window.scrollY + successWrapper.current.getBoundingClientRect().top

    // scroll to position where element is vertically centered in viewport
    let scrollDistance =
      distanceFromTop -
      (window.innerHeight / 2 - successWrapper.current.offsetHeight / 2)

    window.scrollTo({ top: scrollDistance, left: 0, behavior: "smooth" })
  }, [setIsSubmitted])

  return (
    <div ref={successWrapper}>
      <img
        className="block mx-auto pl-5 lg:w-[200px]"
        src={confettiAnimation}
        alt=""
        width="140px"
      />
      <FadeInTop delay={0.3}>
        <h4 className="c-container-full text-center mb-24 font-headings text-2xl lg:text-4xl">
          Thank you for contacting us. We've received your enquiry.
        </h4>
      </FadeInTop>
      {process.env.NODE_ENV == "development" && (
        <button onClick={() => setIsSubmitted(false)}>Reset</button>
      )}
    </div>
  )
}

const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const submitURL = "/.netlify/functions/contact"

  const onSubmit = values => {
    console.log("the values", JSON.stringify(values))
    console.log("the environment variables", process.env)
    return fetch(submitURL, {
      method: "post",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => {
        if (!res.ok) {
          throw Error(res.body)
        }

        setIsSubmitted(true)
      })
      .catch(err => {
        console.log(err)
        setIsSubmitted(false)
        return {
          [FORM_ERROR]: "Something went wrong please try again...",
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      // validate={validate}
      render={({ handleSubmit, submitting, submitError, form }) => {
        return isSubmitted ? (
          <SubmitSuccess setIsSubmitted={setIsSubmitted} />
        ) : (
          <form
            className={`relative c-contact-form c-container-full pb-12 lg:pb-24 ${
              submitting ? "opacity-40" : ""
            }`}
            onSubmit={handleSubmit}
          >
            <div className="c-form-field-wrapper">
              <Field
                disabled={submitting}
                name="firstname"
                id="contact-firstname"
                component="input"
                type="text"
                required
              />
              <label htmlFor="contact-firstname">
                First Name <em>*</em>
              </label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="lastname"
                id="contact-lastname"
                component="input"
                type="text"
                required
              />
              <label htmlFor="contact-lastname">
                Last Name <em>*</em>
              </label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="email"
                id="contact-email"
                component="input"
                type="email"
                required
              />
              <label htmlFor="contact-email">
                Email <em>*</em>
              </label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="phone"
                id="contact-phone"
                component="input"
                type="tel"
              />
              <label htmlFor="contact-phone">Phone Number</label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="company"
                id="contact-company"
                component="input"
                type="text"
              />
              <label htmlFor="contact-company">Company</label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="linkedin"
                id="contact-linkedin"
                component="input"
                type="url"
              />
              <label htmlFor="contact-linkedin">LinkedIn Profile URL</label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="subject"
                id="contact-subject"
                component="input"
                type="text"
                required
              />
              <label htmlFor="contact-subject">
                Subject <em>*</em>
              </label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="message"
                id="contact-message"
                component="input"
                type="text"
                required
              />
              <label htmlFor="contact-message">
                Message <em>*</em>
              </label>
            </div>
            <div className="c-form-field-wrapper">
              <Field name="touchpoint">
                {({ input, meta }) => (
                  <select {...input} id="contact-touchpoint" required>
                    <option value="">--Please select an option--</option>
                    <option value="instagram">Instagram</option>
                    <option value="linkedin">LinkedIn</option>
                    <option value="referral">Referral</option>
                    <option value="ad">Ads</option>
                    <option value="other">Other</option>
                  </select>
                )}
              </Field>
              <label htmlFor="contact-touchpoint">
                How did you hear about us? <em>*</em>
              </label>
            </div>
            <div className="c-form-field-wrapper">
              <Field
                name="touchpoint_other"
                id="contact-touchpoint_other"
                component="input"
                type="text"
              />
              <label htmlFor="contact-touchpoint_other">
                If 'other', please describe
              </label>
            </div>
            <div className="c-contact-form__subscribe cursor-pointer">
              <Field
                name="subscribe"
                id="contact-subscribe"
                component="input"
                type="checkbox"
                className="appearance-none bg-none h-4 w-4 border-gray-300 border-2 rounded-sm focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
              />
              <label
                htmlFor="contact-subscribe"
                className="pl-4 text-white cursor-pointer"
              >
                Would you like to receive insights, news and information from
                us?
              </label>
            </div>
            <div className="c-contact-form__submit relative">
              {/* show error only on form submit error and make error disappear when form is edited after failed submissix`on */}
              {submitError && !form.getState().dirtySinceLastSubmit && (
                <p className="absolute left-0 -top-10 text-red-400">
                  Something went wrong. Please try again...
                </p>
              )}
              <button
                type="submit"
                className="anim-elastic-line block w-fit ml-auto lg:text-xl"
                disabled={submitting}
              >
                {submitting ? (
                  <img
                    className="w-7 top-1/2 left-1/2"
                    src={loadingSpinnerLight}
                    alt=""
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        )
      }}
    />
  )
}

export default ContactPage
